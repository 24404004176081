import { JobStatus } from "@headbot/library";
import * as React from "react";
import { useJob } from "../../../../hooks/api/job/useJob";
import { Gallery } from "../../../molecules/Gallery/Gallery";
import { AdminNavigation } from "../AdminNavigation";

interface IProps {
    readonly jobId: string;
}

export const AdminJobDetailsPage: React.FC<React.PropsWithChildren<IProps>> = ({ jobId }) => {
    const jobData = useJob(jobId);

    if (jobData.isLoading) {
        return (
            <>
                <AdminNavigation />
                <span>Loading...</span>
            </>
        );
    }
    const isJobComplete = jobData.data?.job.status === JobStatus.Completed;
    return (
        <>
            <AdminNavigation />
            {isJobComplete && <Gallery jobId={jobId} />}
        </>
    );
};
