import { JobStatus } from "@headbot/library";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { getAxiosRequestConfig } from "../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../services/Constants/Constants";
import { useAuth } from "../../useAuth";
import { QueryCacheKey } from "../QueryCacheKey";

interface IResponse {
    readonly job: {
        readonly created: string;
        readonly status: JobStatus;
        readonly _purchaseId: string;
        readonly _id: string;
        readonly gender: "male" | "female" | "unspecified";
        readonly timeout: string | null;
        readonly files: Array<{
            readonly _id: string;
            readonly size: number;
            readonly name: string;
            readonly type: string;
        }>;
        readonly uniquePublicViews: number;
    };
}

const getJobs = async (config: AxiosRequestConfig, jobId: string) => {
    const uri = `${Constants.ApiRoot}/job/${jobId}`;
    const { data } = await axios.get<IResponse>(uri, config);
    return data;
};

export const useJob = (jobId: string) => {
    const { authToken } = useAuth();
    const config = getAxiosRequestConfig(authToken);
    return useQuery([QueryCacheKey.Job, jobId], () => getJobs(config, jobId));
};
